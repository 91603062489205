import React, { Component, useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';

function SupplementApprover (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        item,       // current field item
        itemIndex,  // current field item index
        onChange,
    } = props;

    const [fleets, setFleets] = useState([]); // { label, name }
    const [approvers, setApprovers] = useState([]); // { name, title, fleets[] }

    useEffect(() => {
        if ( Array.isArray(fields) && Array.isArray(values) ) {
            const ai = fields.findIndex( f => f.name?.toLowerCase().includes('audience'));
            const auds = [];
            if ( ai >= 0 ) {
                const fa = fields[ai];
                const fv = values[ai];
                fa.properties.selectionData.forEach( item => {
                    if ( item.value && fv.fields) {
                        const iv = fv.fields[item.value];
                        if ( iv ) {
                            const ffi = {
                                label: item.label,
                                name: item.value,
                            };
                            auds.push(ffi);
                        }
                    }
                });
            }
            // console.log('Audiences: ' + JSON.stringify(auds));

            const fi = fields.findIndex( f => f.name?.toLowerCase().includes('fleet'));
            if ( fi >= 0 ) {
                const fd = fields[fi];
                const fv = values[fi];
                // console.log('FLEETS: ' + JSON.stringify(fd));
                // console.log('F VALUE: ' + JSON.stringify(fv));
                /*
                if ( index < values.length ) {
                    console.log('ITEM VALUE = ' + JSON.stringify(values[index].comp));
                }
                */
                if ( Array.isArray(fd.properties?.selectionData)) {
                    const ff = [];
                    const ff2 = [];
                    const allf = fd.properties.selectionData.find( item => item.value && item.value.includes('ALL') && fv.fields && fv.fields[item.value]);
                    fd.properties.selectionData.forEach( item => {
                        if ( item.value && !item.value.includes('ALL') &&  fv.fields) {
                            const iv = fv.fields[item.value];
                            if ( iv || allf) {
                                const ffi = {
                                    label: item.label,
                                    name: item.value,
                                    value: false,
                                };
                                ff.push(ffi);
                                const ffi2 = {
                                    label: 'CC-' + item.label,
                                    name: 'CC_' + item.value,
                                    value: false,
                                }
                                ff2.push(ffi2);
                            }
                        }
                    });
                    // fleets stores the template
                    /*
                    if ( ff.length == 1) {
                        ff[0].value = true;
                    }
                    */
                    const ffa = [];
                    if ( auds.length > 0 ) {
                        auds.forEach( a => {
                            if ( a.name.includes('CABIN') ) {
                                ff2.forEach( f => ffa.push(f));
                            } else {
                                ff.forEach( f => ffa.push(f) );
                            }
                        });
                    } else {
                        ff.forEach( f => ffa.push(f) );
                        setFleets(ffa);
                    }
                    if ( ffa.length == 1) {
                        ffa[0].value = true;
                    }
                    setFleets(ffa);
                    // console.log('FLEETS: ' + JSON.stringify(ffa));
                    let apprs = [];
                    if ( index < values.length && values[index].comp) {
                        apprs = values[index].comp[item.value];
                        if ( !Array.isArray(apprs) ) {
                            apprs = [...approvers];
                        }
                    }
                    let ap = [];
                    if ( apprs.length == 0) {
                        // initialize
                        ap = [{
                            name: '',
                            title: '',
                            fleets: [...ffa],
                        }];
                        setApprovers(ap);
                        // onFleetValueChanged(ap, 0, ap[0].fleets, 0, true);
                    } else {
                        ap = [...apprs];
                        // console.log('OLD APPROVERS: ' + JSON.stringify(approvers));
                        ap.forEach( item => {
                            const ffi = [];
                            ffa.forEach( f => {
                                const ifv = item.fleets.find( i => i.name === f.name);
                                // console.log('FLEET: ' + JSON.stringify(ifv));
                                ffi.push({
                                    label: f.label,
                                    name: f.name,
                                    value: ifv ? ifv.value: false,
                                });
                            })
                            item.fleets = ffi;
                        });
                        setApprovers(ap);
                        
                    }
                    filterApprovers(ap, ffa);
                    
                    // console.log('APPROVERS: ' + JSON.stringify(ap));
                }
            }
        }

    },[fields, values]);

    const save = (apprs) => {
        if ( typeof onChange === 'function' ) {
            onChange(apprs);
        }
    };

    const onNameChanged = (apprs, aindex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        a.name = value;
        setApprovers(ap);
        save(ap);
    };

    const onTitleChanged = (apprs, aindex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        a.title = value;
        setApprovers(ap);
        save(ap);
    };

    const onFleetValueChanged = (apprs, aindex, fleet, findex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        const f = a.fleets[findex];
        // console.log('SEL FLEET: ' + JSON.stringify(f));
        if ( a.fleets.length > 1 ) {
            f.value = value;
            const gff = [...fleets];
            const gf = gff.find(f => f.name === fleet.name);
            if (gf) {
                gf.value = value;
            }
            setFleets(gff);
            // console.log('NEW FLEETS: ' + JSON.stringify(gff));
            // console.log('NEW APPRS: ' + JSON.stringify(ap));
            const rap = filterApprovers(ap, gff);
            save(rap);
        }
    };

    const filterApprovers = (apprs, fleets) => {
        // filter the approvers, the first approver sees all fleets,
        // each subsequent approver sees the leftover from previous
        // if there are not leftovers, remove the approver
        const aprs = [];
        let lff = [...fleets]; // initialize with all fleets
        apprs.forEach ( a => {
            const aff = [];
            lff.forEach(f => {
                aff.push({
                    name: f.name,
                    label: f.label,
                    value: false,
                })
            });
            // copy current value
            if (aff.length > 0) {
                aff.forEach(f => {
                    const af = a.fleets.find(cf => cf.name === f.name);
                    if (af) {
                        f.value = af.value;
                    } else {
                        f.value = false;
                    }
                });

                a.fleets = aff;
                if ( a.fleets.length == 1) {
                    a.fleets[0].value = true;
                    const af = fleets.find( f => f.name === a.fleets[0].name);
                    if ( af ) {
                        af.value = true;
                    }
                }
                aprs.push(a);
            }
            const nlff = aff.filter( f => f.value === false);
            lff = nlff;
        });
        lff = [...fleets];
        lff.forEach(f => f.value = false);
        apprs.forEach( a => {
            a.fleets.forEach(f => {
                if ( f.value ) {
                    const lf = lff.find( l => l.name === f.name);
                    if ( lf ) {
                        lf.value = f.value;
                    }
                }
            });
        });
        setFleets(lff);
        setApprovers(aprs);
        return aprs;
    };

    const canAddApprover = () => {
        const fi = fleets.findIndex( f => f.value === false);
        return fi >= 0;
    };

    const addApprover = () => {
        const ff = fleets.filter( f => f.value === false);
        const naff = [];
        ff.forEach( f => {
            naff.push({
                name: f.name,
                label: f.label,
                value: false,
            });
        })
        const na = {
            name: '',
            title: '',
            fleets: naff,
        };
        if ( na.fleets.length == 1) {
            na.fleets[0].value = true;
            const aff = [...fleets];
            const af = aff.find(f => f.name === na.fleets[0].name);
            if ( af ) {
                af.value = true;
            }
            setFleets(aff);
        }
        const apr = [...approvers];
        apr.push(na);
        setApprovers(apr);
    };

    // testing
    return (
        <Box sx={{paddingBottom: 2}}>
            <Typography>{item?.label}</Typography>
            { Array.isArray(approvers) && approvers.map( (apr, aindex) => 
            <Box sx={{ paddingLeft: 2, paddingTop: 1, display: 'flex', alignItems: 'center' }}>
                <TextField label="Name" size="small" value={apr.name} onChange={event => onNameChanged(approvers, aindex, event.target.value)} />
                <Typography sx={{ paddingLeft: 1, paddingRight: 1 } } >/</Typography>
                <TextField label="Title" size="small" value={apr.title} onChange={event => onTitleChanged(approvers, aindex, event.target.value)} />
                <Box sx={{ paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }} >
                    {(aindex > 0 || apr.fleets.length > 1) && apr.fleets.map((fleet, findex) =>
                        <FormControlLabel control={<Checkbox checked={fleet.value}
                            onChange={(event) => onFleetValueChanged(approvers, aindex, fleet, findex, event.target.checked) } />} label={fleet.label} />
                    )}
                </Box>
            </Box> )
            }
            { canAddApprover() && 
            <Box sx={{paddingLeft: 1}} >
                <IconButton onClick={addApprover}>
                    <AddBoxIcon />
                </IconButton>
            </Box>
            }
        </Box>
    );
}

export {SupplementApprover};